<template>
  <v-container fluid class="pa-0 fill-height">
    <Chat />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import Chat from '../ChatWindow/components/Chat/Chat.vue';

  export default {
    name: 'FSView',

    components: {
      Chat,
    },

    methods: {
      ...mapActions('mobile', ['enableFSMode', 'disableFSMode']),
    },

    created() {
      this.enableFSMode();
    },

    destroyed() {
      this.disableFSMode();
    },
  };
</script>

<style scoped></style>
